'use client';

import { cn } from '@/utils/cn';
// eslint-disable-next-line no-restricted-imports
import { Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react';

const TooltipProvider = Provider;

const Tooltip = Root;

const TooltipTrigger = Trigger;

const tooltipVariants = cva(
    'z-999999 max-w-80 overflow-hidden rounded-md border border-foreground/30 bg-popover px-3 py-1.5 text-sm font-medium text-popover-foreground shadow-xl animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
    {
        variants: {
            variant: {
                default: 'border-foreground bg-foreground text-background',
                destructive:
                    'border border-destructive-foreground/30 bg-destructive text-destructive-foreground',

                warning: 'border border-warning-foreground/30 bg-warning text-warning-foreground',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
);

const TooltipContent = forwardRef<
    ElementRef<typeof Content>,
    ComponentPropsWithoutRef<typeof Content> & VariantProps<typeof tooltipVariants>
>(({ className, variant, sideOffset = 4, ...props }, ref) => (
    <Portal>
        <Content
            ref={ref}
            sideOffset={sideOffset}
            avoidCollisions
            className={cn(tooltipVariants({ variant }), className)}
            {...props}
        />
    </Portal>
));
TooltipContent.displayName = Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
